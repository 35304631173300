<template>
  <div class="container-review-stars">
    <UiIconsStarSolid/>
    <UiIconsStarSolid/>
    <UiIconsStarSolid/>
    <UiIconsStarSolid/>
    <UiIconsStarSolid/>
    {{ $t('quote.customerReview') }}
  </div>
</template>

<style scoped lang="scss">
.container-review-stars {
  text-align: center;

  svg {
    color: var(--color-yellow);
  }
}
</style>
